@import "@/styles/base/_colors";

.productTileWrapper {
  :global {
    .product-listing,
    .search-result,
    .search-side-panel {
      .product-list-tile__wrapper {
        cursor: pointer;
        .social-share {
          position: absolute;
          right: 0;
          &__box {
            right: 5px;
            top: 50px;
          }
        }

        &.--hover {
          .product-list-tile__share {
            opacity: 1;
          }
        }
      }
      @media only screen and (max-width: 1023px) {
        &__tile-col {
          .row-cols-1,
          .row-cols-2 {
            .product-list-tile__share {
              opacity: 1;
              top: 10px;
              right: 10px;
              &-icon {
                background: transparent;
                margin: 11px;
                width: 15px;
                height: 13.3px;
                border: none;
              }
            }
          }
          @media screen and (max-width: 480px) {
            .row-cols-2 {
              .product-list-tile__share {
                top: 2px;
                right: 2px;
              }
            }
          }
        }
      }

      .product-list-tile {
        width: 100%;
        position: relative;

        &__image {
          width: 100%;
          padding-bottom: 100%; //for maintaining aspect ratio
          position: relative;
          overflow: hidden;

          div {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            aspect-ratio: 1 / 1;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            mix-blend-mode: multiply; //temp style for demonstration
            transition: all 0.5s ease-in-out;
          }

          img:first-child {
            position: absolute;
            opacity: 1;
          }
          img:nth-child(2) {
            opacity: 0;
            transform: scale(1.03);
          }
          &:hover {
            .product-list-tile__image--hover {
              opacity: 0;
            }
            img:nth-child(2) {
              opacity: 1;
              transform: scale(1);
            }
            & + .product-list-tile__share {
              opacity: 1;
            }
          }
          @media print {
            break-inside: avoid;
          }
        }

        &__badge {
          display: flex;
          margin-bottom: 5px;
          flex-wrap: wrap;
          row-gap: 8px;
        }
        &__new,
        &__offer {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 12px;
          line-height: 12px;
          letter-spacing: 0.65px;
          background-color: $color-white;
          color: $color-slate;
          border: 1px solid $color-light-gray;
          text-transform: uppercase;
          margin-bottom: 0px;
          padding: 6.5px 8.5px;
          text-align: center;
          margin-right: 10px;
        }

        &__details {
          justify-content: space-between;
          word-break: break-word;
          div {
            &:first-child {
              flex: 1;
            }
          }
        }
        &__sterling {
          cursor: default;
        }

        &__compare-main {
          display: flex;
          margin-top: 10px;
          @media screen and (max-width: 480px) {
            margin-top: 5px;
          }
        }

        &__info {
          margin-top: 15px;
        }
        &__title {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 18px;
          line-height: 1.38;
          letter-spacing: -0.4px;
          color: $color-slate;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
        }

        &__crawlableData {
          display: none;
        }

        &__description {
          font-family: "Helvetica Now Text W05 Regular";
          color: $color-dark-gray;
          font-size: 16px;
          letter-spacing: -0.55px;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &__product-sku {
          color: $color-dark-gray;
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 14px;
          letter-spacing: -0.45px;
          line-height: 20px;
          padding-top: 10px;
        }

        &__price {
          font-family: "Helvetica Now Text W05 Medium";
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.8px;
          color: $color-slate;
          padding-top: 10px;
          font-weight: 500;
        }
        &__discount-price {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.8px;
          color: $color-dark-gray;
          text-decoration: line-through;
          padding-left: 6px;
        }
        &__colors {
          font-family: "Helvetica Now Text W05 Regular";
          font-size: 16px;
          line-height: 1.38;
          letter-spacing: -0.4px;
          color: $color-dark-gray;

          &--swatch {
            position: relative;
            display: flex;
            z-index: 1;
            align-items: center;
            transition: all 0.5s ease-in-out;

            fieldset {
              margin: 0;
              border: 0;
              padding: 0;
            }

            legend {
              display: none;
            }
          }
        }
        &__color-swatch {
          input[type="radio"] {
            display: none;
          }
          &.selected-swatch,
          &.selected-swatch:focus {
            img {
              padding: 3px;
              border: 1px solid $color-charcoal;
              transition: all 0.3s ease-in-out;
            }
          }
          &:hover,
          &:focus {
            img {
              padding: 3px;
              border: 1px solid $color-medium-gray;
              transition: all 0.3s ease-in-out;
            }
          }
          img {
            width: 20px;
            height: 20px;
            border-radius: 5px;
            margin-right: 5px;
            display: block;
          }
        }

        &__compare {
          transition: all 0.5s ease-in-out;
          position: relative;
          flex: none !important;
          float: right;
          .tooltip__container {
            position: absolute;
            z-index: 9;
            right: 0;
          }
          .checkbox {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.4px;
            margin-bottom: 0;
            &_default {
              margin: auto;
            }
          }
          &-button {
            background: none;
            border: none;
            outline: none;
            color: $color-dark-gray;
            z-index: 100;
            &:hover {
              color: $color-dark-gray;
            }
          }
          @media screen and (max-width: 1023px) {
            display: none;
          }
        }

        &__share {
          display: flex;
          position: absolute;
          z-index: 1;
          right: 12px;
          top: 12px;
          transition: all 0.5s ease;
          opacity: 0;

          &-icon,
          &-icon-plp {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            background: $color-off-white;
            border-radius: 5px;
            margin-left: 5px;
            border-color: transparent;
          }
          &-icon-plp {
            @media screen and (max-width: 1023px) {
              display: none;
            }
          }
          &:hover {
            opacity: 1;
          }
        }

        &--lite {
          .product-list-tile {
            &__title {
              font-size: 16px;
              line-height: 1.43;
              letter-spacing: -0.25px;
            }

            &__price {
              font-size: 14px;
              padding-left: 0;
            }
            &__discount-price {
              font-size: 14px;
              padding-left: 6px;
            }
            &_paddingtop {
              padding-top: 15px;
            }
            &__description {
              font-size: 14px;
            }
          }
        }

        &--loading {
          .loading-image {
            width: 100%;
            padding-bottom: 100%;
            position: relative;
            overflow: hidden;
          }
          .loading-title {
            width: 75%;
            height: 10px;
            margin-bottom: 10px;
          }
          .loading-description {
            width: 70%;
            height: 10px;
            margin-bottom: 10px;
          }
          .loading-swatch {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: inline-block;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              margin-right: 5px;
            }
          }
        }

        &__right-controls {
          display: flex;
          flex-direction: column;
          gap: 15px;
        }

        @media only screen and (min-width: 768px) and (max-width: 991px) {
          &--lite &__colors {
            display: block;
          }
          &--lite &__description {
            display: -webkit-box;
          }
          .product-list-tile {
            &__product-sku {
              font-size: 14px;
            }
          }
        }
        @media only screen and (max-device-width: 1023px) and (orientation: landscape) {
          &__title-info {
            height: auto;
          }
          &__control-section {
            margin-top: 45px;
          }
        }
        @media screen and (max-width: 480px) {
          &--lite {
            .product-list-tile {
              &__share {
                opacity: 1;
                display: flex;
                &-icon {
                  background: transparent;
                  width: 20px;
                  height: 20px;
                }
                > div:last-child {
                  display: none;
                }
              }
              &__image {
                margin-bottom: 5px;
              }
              &__compare-main {
                display: block;
              }
              &__product-sku {
                font-size: 12px;
              }
            }
          }
          &__title {
            font-size: 18px;
          }
        }

        @media print and (min-width: 280px) {
          &__right-controls {
            display: none;
          }
        }
      }

      @media only screen and (min-width: 1281px) {
        .promo-col-3.product-listing__tile {
          .plp-promo-banner {
            &__image {
              aspect-ratio: 1553/507;
            }
            &__info-top-left,
            &__info-bottom-left {
              padding: 70px;
            }
          }
        }
        .promo-col-2.product-listing__tile {
          .plp-promo-banner {
            &__image {
              aspect-ratio: 1030/507;
            }
            &__info-top-left,
            &__info-bottom-left {
              padding: 55px;
            }
          }
        }
        .promo-col-1.product-listing__tile {
          .plp-promo-banner {
            &__info-top-left,
            &__info-bottom-left {
              padding: 40px;
            }
          }
        }
      }
      .promo-col-1 {
        &.product-listing__tile {
          .plp-promo-banner {
            &__cta.kf-react-button.ghost {
              margin-top: 20px;
            }
          }
        }
      }
      .promo-col-2 {
        &.product-listing__tile {
          .plp-promo-banner {
            &__cta.kf-react-button.ghost {
              margin-top: 25px;
            }
          }
        }
      }
      .promo-col-3 {
        &.product-listing__tile {
          .plp-promo-banner {
            &__cta.kf-react-button.ghost {
              margin-top: 30px;
            }
          }
        }
      }
      .promo-col-1,
      .promo-col-2,
      .promo-col-3 {
        &.product-listing__tile {
          .plp-promo-banner {
            &__cta.kf-react-button.ghost {
              font-size: 14px;
              padding: 11px 40px;
              line-height: 24px;
            }
          }
        }
      }
      @media only screen and (min-width: 1024px) and (max-width: 1280px) {
        .promo-col-1 {
          &.product-listing__tile {
            .plp-promo-banner {
              &__info-top-left,
              &__info-bottom-left {
                padding: 25px;
              }
              &__info-top-center {
                padding-top: 50px;
              }
              &__desc,
              &__desc * {
                font-size: 20px;
                letter-spacing: -1.15px;
                line-height: 26px;
              }
            }
          }
        }
        .promo-col-2,
        .promo-col-3 {
          &.product-listing__tile {
            .plp-promo-banner {
              &__info-top-left,
              &__info-bottom-left {
                padding: 35px;
              }
              &__info-top-center {
                padding-top: 50px;
              }
              &__image {
                aspect-ratio: 718/351;
              }
              &__desc,
              &__desc * {
                font-size: 26px;
                letter-spacing: -1.5px;
                line-height: 34px;
              }
            }
          }
        }
      }

      &__tile-col--full-width {
        @media only screen and (min-width: 1281px) {
          .product-listing__tile {
            &.promo-col-3 {
              .plp-promo-banner {
                &__image {
                  aspect-ratio: 1819/596;
                }
                &__info-top-left,
                &__info-bottom-left {
                  padding: 82px;
                }
              }
            }
            &.promo-col-2 {
              .plp-promo-banner {
                &__image {
                  aspect-ratio: 1207/596;
                }
                &__info-top-left,
                &__info-bottom-left {
                  padding: 65.5px;
                }
              }
            }
          }
        }
        @media only screen and (min-width: 1024px) and (max-width: 1280px) {
          .promo-col-1 {
            &.product-listing__tile {
              .plp-promo-banner {
                &__info-top-left,
                &__info-bottom-left {
                  padding: 34px;
                }
              }
            }
          }
          .promo-col-2,
          .promo-col-3 {
            &.product-listing__tile {
              .plp-promo-banner {
                &__image {
                  aspect-ratio: 971/478;
                }
                &__info-top-left,
                &__info-bottom-left {
                  padding: 47.33px;
                }
              }
            }
          }
        }
        @media only screen and (min-width: 768px) {
          .promo-col-1 {
            &.product-listing__tile {
              .plp-promo-banner {
                @media screen and (max-width: 1023px) {
                  &__info-top-left,
                  &__info-bottom-left {
                    padding: 25px;
                  }
                  &__info-top-center {
                    padding-top: 50px;
                  }
                  &__desc,
                  &__desc * {
                    font-size: 20px;
                    letter-spacing: -1.15px;
                    line-height: 26px;
                  }
                }
              }
            }
          }
          .promo-col-2,
          .promo-col-3 {
            &.product-listing__tile {
              .plp-promo-banner {
                &__image {
                  @media screen and (max-width: 811px) {
                    aspect-ratio: 2/1;
                  }
                  @media screen and (min-width: 812px) and (max-width: 1023px) {
                    aspect-ratio: 748/368;
                  }
                }
                @media screen and (max-width: 1023px) {
                  &__desc,
                  &__desc * {
                    font-size: 26px;
                    letter-spacing: -1.5px;
                    line-height: 34px;
                  }
                  &__info-top-left,
                  &__info-bottom-left {
                    padding: 35px;
                  }
                }
              }
            }
          }
        }
        @media only screen and (max-width: 767px) {
          .promo-col-1,
          .promo-col-2,
          .promo-col-3 {
            &.product-listing {
              &__tile {
                padding-right: 0;
                .plp-promo-banner {
                  &__image {
                    aspect-ratio: 1/1;
                  }
                  &__info-top-left,
                  &__info-bottom-left {
                    padding: 25px;
                  }
                  &__info-top-center {
                    padding-top: 50px;
                  }
                  &__desc,
                  &__desc * {
                    font-size: 20px;
                    letter-spacing: -1.15px;
                    line-height: 26px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .view-sterling-results {
      width: 100%;
      padding-top: 20px;
      .sterling-results-button {
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #212121;
        color: $color-white;
      }
    }
    .family-of-brands {
      font-family: "Helvetica Now Text W05 Light";
      padding-bottom: 2.6rem;
      line-height: 2.5rem;
      font-weight: 300;
      letter-spacing: -1.6px;
      color: $color-slate;
      height: auto;
      font-size: 2rem;
      width: 55.37rem;
    }
  }
}
